<template>
  <v-container
    class="signin-form leaf--text font-weight-light px-5"
  >
    <v-row
      class="justify-center"
    >
      <v-col
        cols="12"
        class="d-flex justify-center"
        :style="{'margin-top': '10vmin'}"
      >
        <CPDLogo width="35vmin" />
      </v-col>
      <v-col
        cols="12"
        style="max-width: 520px"
        :px-5="$vuetify.breakpoint.smAndUp"
      >
        <h1 class="display-1 font-weight-medium mb-8">
          Reset your password
        </h1>
        <v-form
          dark
          color="leaf"
          class="leaf--text form"
          @submit.prevent="handleSubmit"
        >
          <v-text-field
            v-model="form.password"
            type="password"
            dark
            class="leaf--text zoom"
            label="New password"
            placeholder="Enter a password"
            name="password"
            autocomplete="new-password"
            required
          />
          <v-btn
            large
            block
            class="elevation-0 px-5 text-uppercase mt-4"
            :class="['submit-button', isSubmitDisabled ? 'leaf--text text--darken-2' : 'onyx--text']"
            type="submit"
            :loading="isSubmitting"
            :style="{
              'pointer-events': isSubmitting || isSubmitDisabled ? 'none' : 'all',
              'background-color': isSubmitDisabled ? 'var(--v-metal-darken3)' : 'var(--v-leaf-base)'
            }"
          >
            Reset password
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import CPDLogo from '@/components/application/CPDLogo'
import { mapActions } from 'vuex'

export default {
  name: 'PasswordReset',
  components: {
    CPDLogo
  },
  data () {
    return {
      form: {
        password: ''
      },
      isSubmitting: false
    }
  },
  computed: {
    isSubmitDisabled () {
      return this.form.password.length === 0
    }
  },
  methods: {
    ...mapActions(['showSnackbar']),

    async handleSubmit () {
      const params = new URLSearchParams(window.location.search)
      this.isSubmitting = true
      try {
        await this.$axios.post('/users/reset_password', {
          user: params.get('user'),
          token: params.get('token'),
          password: this.form.password
        })
        this.showSnackbar('You have successfully reset your password. Please sign in.')
      } catch (error) {
        this.showSnackbar({ color: 'error', text: 'Unable to reset your password. You are likely using an expired link. Please try again, or get in touch if the problem continues.' })
      } finally {
        this.isSubmitting = false
        this.$router.push('/start')
      }
    }
  }
}

</script>
